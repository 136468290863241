import { AvoEditorPreview } from 'components/utils/avoeditor/AvoEditorPreview';
import { SlidePanel } from 'components/utils/panels/AvoSlidePanel';
import { Body2, H4 } from 'components/utils/typo';
import 'css/custom.css';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { UIElement } from '../../../api/moduleElement';
import { GptBoxForm, GptModelValues } from '../../panels/GPTBoxForm';
import { ModuleContext } from '../../utils/module/ModuleContext';
import { DraggableContext, ModuleElementContext } from '../ModuleBoard';
import { CardContainer } from './utils/CardContainer';
import { useTriggers } from '../../../hooks/module/resources/useTriggers';

interface GptBoxCardProps {
  element: UIElement;
  containerId?: number;
}

export interface GptBoxFormVariable {
  module: number;
  trigger: number;
  title: string;
  containerCard: number;
  position: number;
  selectedTemplate: number | null;
  // prompt: is used for calculating token limit. not using anymore.
  prompt_json: any; // Need to change to JSONContent after tiptap migration;
  prompt_json_tiptap: any;
  variable: string;
  is_hide: boolean;
  hide_option: 'sneak' | 'hide-reasoning' | 'hide-all';
  model: GptModelValues;
}

export const GptBoxCard = ({ element, containerId }: GptBoxCardProps) => {
  const { module } = useContext(ModuleContext);
  const { setIsDragDisabled } = useContext(DraggableContext);
  const [formOpen, setFormOpen] = useState(!element.id);
  useEffect(() => setIsDragDisabled(formOpen), [formOpen]);

  const {
    data: { triggers, candidate_triggers },
  } = useTriggers();
  const triggerList = [...triggers, ...candidate_triggers];

  const alwayOnTrigger = triggerList.find((data) => data.title === 'Always On');

  const defaultValues = {
    module: module!.id,
    trigger: element.trigger ? element.trigger.id : alwayOnTrigger?.id,
    title: element.title || '',
    container: containerId,
    position: element.position,
    selectedTemplate: null,
    prompt_json: element.prompt_json,
    prompt_json_tiptap: element.prompt_json_tiptap,
    variable: element.variable_item?.name || '',
    is_hide: element.is_hide ?? false,
    hide_option: element.hide_option ?? 'sneak',
    model: (element.model || 'gpt-4o') as GptModelValues,
  };

  const form = useForm<GptBoxFormVariable>({ defaultValues });

  const { watch, handleSubmit } = form;
  const { refetchModuleElements, clearModuleElements } = useContext(ModuleElementContext);
  const onCancel = () => {
    clearModuleElements();
    form.reset(defaultValues);
    setFormOpen(false);
  };

  const onSave = () => {
    try {
      refetchModuleElements();
      setFormOpen(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <FormProvider {...form}>
      <CardContainer
        elementId={element.id}
        onOpenPanel={() => setFormOpen(true)}
        isPreviewing={formOpen}
        isContainer={!!containerId}
        variables={[{ type: 'gpt', name: form.watch('variable') }]}
      >
        <H4 className='text-gray-900'>{watch('title')}</H4>
        <Body2 className='text-gray-900'>
          <AvoEditorPreview
            contentTiptap={form.watch('prompt_json_tiptap')}
            contentDraftJS={form.watch('prompt_json')}
            isLineClampTwo
          />
        </Body2>
      </CardContainer>

      <SlidePanel open={formOpen}>
        <GptBoxForm gptboxId={element.id} onSave={onSave} onCancel={onCancel} />
      </SlidePanel>
    </FormProvider>
  );
};
