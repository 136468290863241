import { Container } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import Footer from 'components/layout/Footer';
import History from 'components/utils/History';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import 'css/custom.css';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { toast } from 'react-toastify';
import { NUMBER_CREATE } from 'routes/urls';
import arrayMove from 'utils/arrayMove';
import { NUMBER_ROUTE, NUMERICS_API_URL } from '../../constants';
import { withAuthentication } from '../../hooks/useAuthentication';
import { CustomToast } from '../utils/toast-message';
import {
  StyledContainer,
  StyledDivider,
  StyledTable,
  StyledTableCell,
  StyledTableCellIcon,
  StyledTableRow,
} from './styles';
interface SortableRowProps {
  item: any; // Define the type of 'item' prop
  index: number;
  toggleModal: (id: any) => void;
}
const SortableRow = SortableElement<SortableRowProps>(({ item, toggleModal }) => (
  <StyledTableRow>
    <StyledTableCell>{item.title}</StyledTableCell>
    <StyledTableCell>{item.unit}</StyledTableCell>
    <StyledTableCell>{item.upper_limit}</StyledTableCell>
    <StyledTableCell>{item.lower_limit}</StyledTableCell>
    <StyledTableCell>{item.validity_period_days}</StyledTableCell>

    <StyledTableCellIcon onClick={() => toggleModal(item.id)} style={{ cursor: 'pointer' }}>
      <i className='material-icons' style={{ cursor: 'pointer' }}>
        delete
      </i>
    </StyledTableCellIcon>

    <StyledTableCellIcon
      align='center'
      style={{ cursor: 'pointer' }}
      onClick={() =>
        History.push({
          pathname: `${NUMBER_ROUTE}/${item.id}`,
          state: { numericObj: item },
        })
      }
    >
      <Link
        to={{
          pathname: `${NUMBER_ROUTE}/${item.id}`,
        }}
        state={{ numericObj: item }}
      >
        <i className='material-icons' style={{ cursor: 'pointer' }}>
          edit
        </i>
      </Link>
    </StyledTableCellIcon>
  </StyledTableRow>
));
interface SortableBodyProps {
  items: any[]; // Define the type of 'items' prop
  toggleModal: (id: any) => void;
  onSortEnd: ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => void;
  distance?: number;
}

const SortableBody = SortableContainer<SortableBodyProps>(({ items, toggleModal }) => {
  return (
    <TableBody>
      {items.map((item: any, index: number) => (
        <SortableRow key={item.id} index={index} item={item} toggleModal={toggleModal} />
      ))}
    </TableBody>
  );
});

class NumericsList extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      numerics: [],
      numericId: '',
      modal: false,
      searchInput: '',
      deleting: false,
      positionModified: false,
      activeState: 'Numerics',
    };
  }

  componentDidMount() {
    // save position on page refresh and tab close
    if (window.performance) {
      if (performance.navigation.type === 1) {
        this.savePosition();
      }
    }

    window.addEventListener('beforeunload', this.savePosition);

    // get all the numerics
    this.resetState();
    this.setState({
      activeState: this.props.activeState,
    });
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.savePosition);
    this.savePosition();
  }

  checkHasDuplicate = () => {
    const { numerics } = this.state;
    let termsLoinc = new Set();
    let termsText = new Set();

    for (const item of numerics) {
      for (const term of item.term_loinc) {
        if (!term) continue;
        if (termsLoinc.has(term)) {
          toast.error(CustomToast, {
            data: `Please check the ${item.title}. If this code is the same as another variable's LOINC code, the system can't find the appropriate value.`,
          });
        }
        termsLoinc.add(term);
      }

      for (const term of item.term_text) {
        if (!term) continue;
        const lowerTerm = term.toLowerCase();
        if (termsText.has(lowerTerm)) {
          toast.error(CustomToast, {
            data: `Please check the ${item.title}. If this code is the same as another variable's Text code, the system can't find the appropriate value.`,
          });
        }
        termsText.add(lowerTerm);
      }
    }
    return;
  };

  savePosition = (): Promise<any>[] => {
    if (this.state.positionModified === true) {
      const promises: Promise<any>[] = [];
      for (const [index, numeric] of this.state.numerics.entries()) {
        promises.push(
          axios.patch(NUMERICS_API_URL + numeric.id + '/', {
            id: numeric.id,
            position: index + 1,
          })
        );
      }

      this.setState({ positionModified: false });
      return promises;
    }
    return [];
  };

  // for open/close modal
  toggleModal = (id) => {
    this.setState((previous) => ({
      modal: !previous.modal,
      numericId: id,
    }));
  };

  resetState = () => {
    // send request to django endpoint to fetch list of all numeric objects
    axios
      .get(NUMERICS_API_URL)
      .then((res) => this.setState({ numerics: res.data }, this.checkHasDuplicate));
  };

  deleteNumeric = (NumericId) => {
    this.setState({ deleting: true });
    let promises = this.savePosition();

    // wait for save Position to complete
    Promise.all(promises).then(() => {
      // delete numeric object and reload list
      axios
        .delete(NUMERICS_API_URL + NumericId + '/')
        .then(() => {
          this.setState({ deleting: false });
          this.resetState();
        })
        .catch(() => {
          this.setState({ deleting: false });
        });
    });
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ numerics }) => ({
      numerics: arrayMove(numerics, oldIndex, newIndex),
      positionModified: true,
    }));
  };

  render() {
    const numerics = this.state.numerics;
    const isStaff = this.props.authentication.user?.is_staff;
    const currentRoute = History.location.pathname;

    return (
      <>
        <div className='second-container'>
          <Backdrop
            style={{
              zIndex: 2000,
              color: '#08A88E',
              background: 'rgba(214, 216, 219, 0.9)',
            }}
            open={this.state.deleting}
          >
            <CircularProgress style={{ color: '#08A88E' }} />
          </Backdrop>

          {isStaff && (
            <StyledContainer fixed className={this.props.variantStyle ? '!w-full !pl-0 !pt-0' : ''}>
              <Container className={this.props.variantStyle ? '!pl-0' : ''}>
                <Container>
                  <Grid container alignItems='center'>
                    <Grid item xs>
                      <Typography gutterBottom variant='h4'>
                        Numerics
                      </Typography>
                    </Grid>
                    <Grid item alignItems='center'>
                      <Link to={`${NUMBER_CREATE}`} className='link-btn right'>
                        Create Numeric
                      </Link>
                    </Grid>
                  </Grid>

                  <Typography color='textSecondary' variant='body1'>
                    List of possible laboratory tests, their units, and upper and lower limits of
                    normal.
                  </Typography>
                </Container>

                <StyledDivider variant='middle' />

                <TableContainer>
                  <StyledTable style={{ width: '100%' }}>
                    <TableHead style={{ width: '100%' }}>
                      <StyledTableRow>
                        <StyledTableCell>Title</StyledTableCell>
                        <StyledTableCell>Unit</StyledTableCell>
                        <StyledTableCell>
                          Upper <br /> limit
                        </StyledTableCell>
                        <StyledTableCell>
                          Lower <br />
                          limit
                        </StyledTableCell>
                        <StyledTableCell>Validity Period (day)</StyledTableCell>
                        <StyledTableCell colSpan={2}>Action</StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <SortableBody
                      distance={1} // this means we needs to slightly move the element to start sorting, we need this to made edit/delete icons clickable
                      items={numerics}
                      toggleModal={this.toggleModal}
                      onSortEnd={this.onSortEnd}
                    />
                  </StyledTable>
                </TableContainer>
              </Container>
            </StyledContainer>
          )}

          <ConfirmModal
            preset='delete'
            open={this.state.modal}
            content='Are you sure you want to delete this Numeric?'
            toggleModal={() => this.toggleModal(0)}
            performAction={() => {
              this.toggleModal(0);
              this.deleteNumeric(this.state.numericId);
            }}
          />
          <div className='separate' />
        </div>
        <Footer />
      </>
    );
  }
}

export default withAuthentication(NumericsList);
