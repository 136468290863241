import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Editor, JSONContent } from '@tiptap/react';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { resourcesAPI } from '../../../api/resources';
import { MODULE_TYPES } from '../../../constants';
import { ComboBox, DropdownValue } from '../../utils/ComboBox';
import { MentionProvider } from '../../utils/module/MentionContext';
import { ModuleContext } from '../../utils/module/ModuleContext';
import { Footer } from '../../utils/panels/Footer';
import { Header } from '../../utils/panels/Header';
import { Tiptap } from '../../utils/tiptap/Tiptap';
import { SuggestionTypeEnum } from '../../utils/tiptap/tiptapInterfaces';
import { CustomToast } from '../../utils/toast-message';
import { APIResourceAuthorization, APIResourceBody } from './APIResourceBody';
import { flattenOptions, formatJson, generateOptions } from './APIResourceUtil';
import {
  APIResource,
  APIResourceFormPayload,
  ContentTypes,
  HTTPMethods,
  OutputTypes,
} from './types';

const excludedSuggestionTypes = Object.values(SuggestionTypeEnum).filter(
  (type) => type !== SuggestionTypeEnum.TEXT_INPUT_VARIABLE && type !== SuggestionTypeEnum.VARIABLE
);

export const APIResourceForm = ({
  resource,
  onClose,
}: {
  resource: APIResource | null;
  onClose: () => void;
}) => {
  const { module } = useContext(ModuleContext);
  const defaultValues: APIResourceFormPayload = {
    id: resource?.id,
    title: resource?.title ?? '',
    url: resource?.url ?? '',
    url_json: resource?.url_json ?? null,
    method: resource?.method ?? HTTPMethods.GET,
    authorization: resource?.authorization ?? null,
    content_type: resource?.content_type ?? ContentTypes.JSON,
    body: resource?.body ?? null,
    output_type: resource?.output_type ?? OutputTypes.OBJECT,
    output_type_definition:
      resource?.output_type_definition ?? '{ "input your key here": "input your value here" }',
    output_you_want_to_use: resource?.output_you_want_to_use ?? '',
    module: module?.type === MODULE_TYPES.ALGO ? module?.id : undefined,
    calculator: module?.type === MODULE_TYPES.CALCULATOR ? module?.id : undefined,
  };

  const { watch, register, handleSubmit, setValue } = useForm<APIResourceFormPayload>({
    defaultValues,
  });

  const [isValidJson, setIsValidJson] = useState(true);
  const [outputTypeOptions, setOutputTypeOptions] = useState<DropdownValue[]>([]);
  const outputTypeDefinition = watch('output_type_definition');
  useEffect(() => {
    try {
      JSON.parse(outputTypeDefinition);
      setIsValidJson(true);

      const parsedOutputTypeDefinition = JSON.parse(outputTypeDefinition);
      const outputTypeOptions = generateOptions(parsedOutputTypeDefinition);
      setOutputTypeOptions(flattenOptions(outputTypeOptions));
    } catch (error) {
      setIsValidJson(false);
      setOutputTypeOptions([]);
    }
  }, [outputTypeDefinition]);

  const queryClient = useQueryClient();
  const { mutate, isPending: isMutating } = useMutation({
    mutationFn: resourcesAPI.upsertApiResource,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [module?.type, module?.id, 'api-resources'] });
      onClose();
    },
  });

  const onSubmit = async (data: APIResourceFormPayload) => {
    if (isMutating) return;

    if (isDisabled) {
      toast.error(CustomToast, { data: 'Please fill out required fields.' });
      return;
    }
    mutate(data);
  };

  const onKeyDown = (e: any) => {
    if (e.key === 'Enter' && e.target.nodeName !== 'TEXTAREA') {
      e.preventDefault();
    }
  };

  const [isUrlEmpty, setIsUrlEmpty] = useState(!defaultValues.id);
  const isDisabled = isUrlEmpty;

  return (
    <div className='h-full px-[16px]'>
      <Header
        className='sticky top-0 z-10 bg-white !px-0 !pr-3 pt-[20px]'
        title={!!resource?.id ? 'Update API Resource' : 'Create API Resource'}
        toggleModal={onClose}
      />
      <form
        autoComplete='off'
        onKeyDown={onKeyDown}
        onSubmit={handleSubmit(onSubmit)}
        className='flex h-full flex-col pb-0'
      >
        <div className='mb-4'>
          <label className='block text-black'>
            <div>Title:</div>
            <input
              {...register('title', { required: true })}
              className='w-full rounded border border-gray-300 px-[12px] py-[10px] focus:outline-none'
            />
          </label>

          <label className='mt-4 block text-black'>
            URL:
            <MentionProvider
              excludedSuggestionTypes={excludedSuggestionTypes}
              performChoiceCodeSubstitution
            >
              <Tiptap
                maxLines={1}
                wrapperClassNames='!max-h-[45px]'
                onUpdate={(editor: Editor) => {
                  setIsUrlEmpty(!editor.getText().trim().length);
                  setValue('url_json', editor.getJSON());
                }}
                initialContent={watch('url_json')}
                allowNullSuggestionPrefix={true}
              />
            </MentionProvider>
          </label>

          <label className='mt-4 block text-black'>
            Content Type:
            <ComboBox
              onChange={(value) => setValue('content_type', value as ContentTypes)}
              options={Object.values(ContentTypes).map((contentType) => ({
                label: contentType,
                value: contentType,
              }))}
              selectedValue={watch('content_type')}
            />
          </label>

          <label className='mt-4 block text-black'>Body:</label>
          <APIResourceBody
            initBody={watch('body')}
            onChange={(value: JSONContent) => setValue('body', value)}
          />

          <label className='mt-4 block text-black'>
            Method:
            <ComboBox
              onChange={(value) => setValue('method', value as HTTPMethods)}
              options={Object.values(HTTPMethods).map((method) => ({
                label: method,
                value: method,
              }))}
              selectedValue={watch('method')}
            />
          </label>

          <label className='mt-4 block text-black'>
            Authorization:
            <APIResourceAuthorization
              initBody={watch('authorization')}
              onChange={(value: JSONContent) => setValue('authorization', value)}
            />
          </label>

          <label className='mt-4 block text-black'>
            Output Type:
            <ComboBox
              onChange={(value) => setValue('output_type', value as OutputTypes)}
              options={Object.values(OutputTypes).map((outputType) => ({
                label: outputType,
                value: outputType,
              }))}
              selectedValue={watch('output_type')}
            />
          </label>

          <label className='mt-4 block text-black'>
            Output Type Definition (JSON):
            <textarea
              {...register('output_type_definition', {
                value: isValidJson ? formatJson(outputTypeDefinition) : outputTypeDefinition,
              })}
              style={{ minHeight: '200px' }} // as below tailwind not working
              className='min-h-100 resize-none rounded border border-gray-300 px-[12px] py-[10px] focus:outline-none'
            />
            {!isValidJson && (
              <p className='mt-2 text-red-500'>Invalid JSON syntax. Please enter valid JSON.</p>
            )}
          </label>

          <label className='mt-4 block text-black'>
            Output You Want to Use:
            <ComboBox
              onChange={(value) => setValue('output_you_want_to_use', value as string)}
              options={outputTypeOptions}
              selectedValue={watch('output_you_want_to_use')}
            />
            <p className='mt-2 text-gray-500'>
              Selected Key: {watch('output_you_want_to_use') || 'No key selected'}
            </p>
          </label>

          <Footer disabled={isDisabled} onClose={onClose} isLoading={isMutating} />
        </div>
      </form>
    </div>
  );
};
